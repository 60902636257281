
import { defineComponent } from "vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import ValidateError from "@/components/ValidateError.vue";
import { PageService } from "@/services/PageService";

export default defineComponent({
  name: "CalendarHeader",
  props: ["mode", "predictPrices", "confirmedPrices"],
  components: {
    ErrorMessage,
    ValidateError,
  },
  data: () => ({
    isShow: false,
    pageService: new PageService(),
    message: "",
    saleData: {} as any,
  }),
  mounted: async function () {
    this.isShow = true;
  },
  methods: {
    commonError: function (result: any = null): void {
      if (result.message !== "") {
        this.message = result.message;
      }
    },
  },
});
