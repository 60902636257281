import axios from "axios";
import { ApiUrls } from "@/consts/ApiUrls";
import { BaseService } from "@/services/BaseService";
import { PageService } from "@/services/PageService";

export class SaleService extends BaseService {
  constructor() {
    super();
  }

  async indexApi(search: any = {}): Promise<any> {
    return await this.get(ApiUrls.sales(), search)
      .then((response) => {
        return this.baseSuccess(response);
      })
      .catch((error) => {
        console.log(error.status);
        return this.baseError(error);
      });
  }

  async getSalesByNowMonth(search: any = {}): Promise<any> {
    search.year_month = this.getNowYearMonth();
    return this.indexApi(search);
  }

  getFullCalendarBySales(saleData: any[]): any[] {
    let result: any[] = [];
    for (const sale of saleData) {
      result.push(this.getSaleDadta(sale));
    }
    return result;
  }

  private getSaleDadta(sale: any): any {
    return {
      title: sale.sale_deliveries[0].name + "|" + sale.details[0].product_name,
      start: this.getDateTimeByUnixTime(sale.make_date),
    };
  }

  private getDateTimeByUnixTime(unixTime: number): string {
    let dateTime = new Date(unixTime * 1000);
    const year = dateTime.getFullYear(); // 年
    const month = new PageService().get0Padding(dateTime.getMonth() + 1, 2); // 月
    const day = new PageService().get0Padding(dateTime.getDate(), 2); // 日
    return String(year) + "-" + String(month) + "-" + String(day);
  }

  private getNowYearMonth(): String {
    const now = new Date();
    const year = now.getFullYear(); // 年
    const month = new PageService().get0Padding(now.getMonth() + 1, 2); // 月
    return String(year) + "-" + String(month);
  }

  async sendApi(saleId: number, sendNumber: string): Promise<any> {
    return await this.put(ApiUrls.salesSend(saleId), {
      send_number: sendNumber,
    })
      .then((response) => {
        return this.baseSuccess(response);
      })
      .catch((error) => {
        console.log(error.status);
        return this.baseError(error);
      });
  }

  async saleDataApi(yearMonth: string): Promise<any> {
    return await this.get(ApiUrls.salesSaleData(), {
      year_month: yearMonth,
    })
      .then((response) => {
        return this.baseSuccess(response);
      })
      .catch((error) => {
        console.log(error.status);
        return this.baseError(error);
      });
  }

  async okOrderApi(saleId: number): Promise<any> {
    return await this.put(ApiUrls.salesOkOrder(saleId), {})
      .then((response) => {
        return this.baseSuccess(response);
      })
      .catch((error) => {
        console.log(error.status);
        return this.baseError(error);
      });
  }

  async noOrderApi(
    saleId: number,
    noOrderStatus: number,
    noOrderReason: string
  ): Promise<any> {
    return await this.put(ApiUrls.salesNgOrder(saleId), {
      no_order_status: noOrderStatus,
      no_order_reason: noOrderReason,
    })
      .then((response) => {
        return this.baseSuccess(response);
      })
      .catch((error) => {
        console.log(error.status);
        return this.baseError(error);
      });
  }
}
