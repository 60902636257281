import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-3 mt-4 w-75 mx-auto" }
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ]))
}