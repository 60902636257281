import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04ab244c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorMessages, (errorMessage) => {
      return (_openBlock(), _createElementBlock("p", {
        key: errorMessage,
        class: "validate-error text-start p-0 m-0"
      }, _toDisplayString(errorMessage), 1))
    }), 128))
  ]))
}