import request from "@/utils/request";

export const okOrderSaleApi = (id: number): Promise<any> =>
  request({
    url: `sales/${id}/ok-order`,
    method: "post",
    data: {}
  });

export const noOrderSaleApi = (
  id: number,
  noOrderStatus: string,
  noOrderReason: string
): Promise<any> =>
  request({
    url: `sales/${id}/no-order`,
    method: "post",
    data: {
      no_order_status: noOrderStatus,
      no_order_reason: noOrderReason,
    }
  });

export const sendSaleApi = (
  id: number,
  sendNumber: string
): Promise<any> =>
  request({
    url: `sales/${id}/send`,
    method: "put",
    data: {
      send_number: sendNumber,
    }
  });

export const saleDataSaleApi = (
  yearMonth: string
): Promise<any> =>
  request({
    url: "/sales/sale-data",
    method: "get",
    params: {
      year_month: yearMonth
    }
  });

export const storeSaleApi = (
  data : any
): Promise<any> =>
  request({
    url: "/sales",
    method: "post",
    data: data
  });

export const indexSaleApi = (params: any): Promise<any> =>
  request({
    url: "/sales",
    method: "get",
    params: params,
  });

export const saleMonthDataApi = (yearMonth: string): Promise<any> =>
  request({
    url: "/sales/saleMonthData",
    method: "get",
    params: {
      year_month: yearMonth
    },
  });

export const saleWeekDataApi = (date: string): Promise<any> =>
  request({
    url: "/sales/saleWeekData",
    method: "get",
    params: {
      date: date
    },
  });


export const salesDownloadCsvUrlApi = (saleIds: number[]): Promise<any> =>
  request({
    url: "/sales/downloadCsvUrl",
    method: "post",
    data: {
      sale_ids: saleIds
    },
  });

export const salesDownloadInvoicePdfUrlApi = (saleIds: number[]): Promise<any> =>
  request({
    url: "/sales/downloadInvoicePdfUrl",
    method: "post",
    data: {
      sale_ids: saleIds
    },
  });
